@import 'theme/_s1v10colors.scss';

.xs-3 .checkbox__wrapper label {
  justify-content: flex-start;
}

.xs-3 .checkbox__wrapper {
  border-right: none;
}

.checkbox__wrapper {
  width: 100%;
}

.checkbox__wrapper svg {
  width: 20px !important;
  height: 20px !important;
  padding-top: 1px;
}

.checkbox__wrapper_blue:not(.checkbox__wrapper--disabled) svg{
  fill: $text-primary !important;
}

.checkbox__wrapper .MuiCheckbox-colorSecondary.Mui-checked {
  color: $primary-main;
}

.checkbox__wrapper label {
  line-height: 23px !important;
  user-select: none;
  margin-left: 0px !important;
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.checkbox__wrapper__checkbox {
  font-size: 13px;
  color: $grey-600;
  padding: 0 !important;
  margin-right: 30px !important;
}

.checkbox__wrapper__label {
  color: $text-primary !important;
  font-size: 13px !important;
}

.checkbox__wrapper__label-black {
  color: $text-primary !important;
}

.checkbox-placeholder__wrapper {
  border: 1px solid $text-primary;
  padding: 5px 0 5px 10px;
  margin-top: 10px;

  .checkbox__wrapper_checked:not(.checkbox__wrapper--disabled) svg{
    fill: $primary-main !important;
  }

  .checkbox__wrapper__checkbox {
    > div > div{
      margin-left: 29px !important;
    }
  }
}

.checkbox-placeholder-form__wrapper {
  display: flex;
  align-items: center;

  .checkbox__wrapper_checked:not(.checkbox__wrapper--disabled) svg {
    fill: $text-primary !important;
  }

  .label__container {
    display: flex;
    flex: 1 0 100px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    div:last-child {
      padding-right: 15px;
    }

    .label__container-left {
      display: flex;
      align-items: center;
      color: $text-primary;
    }

    .checkbox__label {
      display: flex;
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      padding-left: 5px;
      padding-right: 5px;
      user-select: none;
    }

    .checkbox__label-box {
      display: block;
      width: 30px;
      font-size: 11pt;
      font-weight: 500;
      padding-right: 10px;
    }
  }

  .checkbox__container {
    margin: 0 0 0 -15px;

    .checkbox__wrapper__checkbox {
      @extend .checkbox__wrapper__checkbox;
    }
  }
}

div[label="Output options"], div[label="Solicitations"] > .sovos-dynamic-form__dynamic-form-component {
  margin-bottom: 4px;

  .sovos-dynamic-form__fields > div {
    min-height: 30px;

    .checkbox__wrapper > label {
      height: 30px;
    }
  }
}

$version: 15.0.3;
$primary-light: #C9D9F2;
$primary-main: #015FFF;
$primary-dark: #24489E;
$primary-contrastText: #ffffff;
$destructive-light: #FFC3B6;
$destructive-main: #DB322D;
$destructive-dark: #AB1117;
$destructive-contrastText: #ffffff;
$warning-light: #FEF8E2;
$warning-main: #F0D354;
$warning-dark: #C3A127;
$warning-contrastText: rgba(0, 0, 0, 0.87);
$error-light: #FFD5CC;
$error-main: #F76F72;
$error-dark: #CC5C5E;
$error-contrastText: rgba(0, 0, 0, 0.87);
$success-light: #EBFFDE;
$success-main: #7EBB69;
$success-dark: #4E783B;
$success-contrastText: rgba(0, 0, 0, 0.87);
$info-light: #D8F1FA;
$info-main: #3DBBE7;
$info-dark: #2A8AAE;
$info-contrastText: rgba(0, 0, 0, 0.87);
$text-primary: #072448;
$text-secondary: #545E75;
$text-disabled: #9DA2A7;
$text-hint: #70757a;
$text-contrast: #ffffff;
$grey-50: #F5FAFF;
$grey-100: #F0F5FA;
$grey-200: #E9EEF3;
$grey-300: #DBE0E5;
$grey-400: #B8BDC2;
$grey-500: #999EA3;
$grey-600: #70757A;
$grey-700: #5C6166;
$grey-800: #3D4247;
$grey-900: #1C2126;
$grey-A100: #E0EAF8;
$grey-A200: #7390CF;
$grey-A400: #031B37;
$grey-A700: #072A51;
$divider: #DBE0E5;
$background-default: #ffffff;
$background-paper: #ffffff;
$action-activatedOpacity: 0.16;
$action-active: #545E75;
$action-disabled: #BABDC0;
$action-disabledBackground: #DBE0E5;
$action-disabledOpacity: 0.5;
$action-focus: rgba(84, 94, 117, 0.16);
$action-focusOpacity: 0.16;
$action-hover: rgba(84, 94, 117, 0.08);
$action-hoverOpacity: 0.08;
$action-selected: rgba(84, 94, 117, 0.12);
$action-selectedOpacity: 0.12;
$cyan-100: #D8F1FA;
$cyan-200: #B1E4F5;
$cyan-600: #2CA7D0;
$cyan-700: #2A8AAE;
$orange-100: #FEE9D5;
$orange-200: #FDD3AB;
$orange-500: #FA8F2D;
$orange-600: #DB7D28;
$dataVisualization-blue50: #4E7AD7;
$dataVisualization-blue70: #24489E;
$dataVisualization-blue80: #183978;
$dataVisualization-cyan60: #2CA7D0;
$dataVisualization-cyan80: #256784;
$dataVisualization-cyan90: #0B4A64;
$dataVisualization-green60: #689F50;
$dataVisualization-magenta50: #E46DBB;
$dataVisualization-orange40: #FBA657;
$dataVisualization-orange90: #7D4817;
$dataVisualization-purple50: #A56BD3;
$dataVisualization-purple70: #7C519F;
$dataVisualization-purple90: #53366A;
$dataVisualization-red60: #E26668;
$dataVisualization-red70: #CC5C5E;
$dataVisualization-red90: #7A3738;
$dataVisualization-yellow50: #F0D354;
$dataVisualization-yellow70: #C3A127;

.search-bar {
  &__container {
    position: relative;

    &-wrapper {
      position: absolute;
      right: 0;
      top: -30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__fields {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow-x: hidden;
    transition: width 0.5s;

    .validation-text-field__root {
      height: 66px;
    }

    &--open {
      width: 100%;
    }

    &--closed {
      width: 0;
    }
  }

  &__input {
    width: 400px;
    margin-right: 5px;
    padding-top: 8px;
  }

  &__select {
    width: 150px !important;
    margin-top: -21px;
  }
}
